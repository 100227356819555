import { Grid, makeStyles } from '@material-ui/core';
import content from '../../../content/team/employees.json';
import { defaultSectionWidth, largeText, pageTitle, smallSubtitle, smallText } from '../../../styles/dimensions';
import colors from '../../../styles/colors';
import RigrafExpanded from '../../../fonts/rigraf/expanded/rigrafExpanded';
import MetroSans from '../../../fonts/metro-sans/metroSans';
import { StaticImage } from 'gatsby-plugin-image';

const Employees = () => {
	const classes = useClasses();
	return (
		<section className={classes.section}>
			<Grid container spacing={4} classes={{ root: classes.gridRoot }} data-scroll data-scroll-speed="2">
				<Grid item xs={12}>
					<h2 className={classes.header}>{content.title}</h2>
				</Grid>

				<Grid item xs={12} sm={6} md={4} lg={3}>
					<StaticImage src="../../../images/team/employees/placeholder.png" alt="" aria-hidden="true" />
					<h5 className={classes.employeeName}>Antonio Nešić</h5>
					<p className={classes.employeePosition}>Developer</p>
				</Grid>

				<Grid item xs={12} sm={6} md={4} lg={3}>
					<StaticImage src="../../../images/team/employees/placeholder.png" alt="" aria-hidden="true" />
					<h5 className={classes.employeeName}>Ivona Bajsić</h5>
					<p className={classes.employeePosition}>Talent Recruiter</p>
				</Grid>

				<Grid item xs={12} sm={6} md={4} lg={3}>
					<StaticImage src="../../../images/team/employees/josip_blazevic.png" alt="Josip Blažević" />
					<h5 className={classes.employeeName}>Josip Blažević</h5>
					<p className={classes.employeePosition}>Lead Developer</p>
				</Grid>

				<Grid item xs={12} sm={6} md={4} lg={3}>
					<StaticImage src="../../../images/team/employees/placeholder.png" alt="" aria-hidden="true" />
					<h5 className={classes.employeeName}>Josip Pokos</h5>
					<p className={classes.employeePosition}>Sales Representative</p>
				</Grid>

				<Grid item xs={12} sm={6} md={4} lg={3}>
					<StaticImage src="../../../images/team/employees/katarina_esegovic.png" alt="Katarina Ešegović" />
					<h5 className={classes.employeeName}>Katarina Ešegović</h5>
					<p className={classes.employeePosition}>Marketing Specialist / HR</p>
				</Grid>

				<Grid item xs={12} sm={6} md={4} lg={3}>
					<StaticImage src="../../../images/team/employees/luka_patrun.png" alt="Luka Patrun" />
					<h5 className={classes.employeeName}>Luka Patrun</h5>
					<p className={classes.employeePosition}>Developer</p>
				</Grid>

				<Grid item xs={12} sm={6} md={4} lg={3}>
					<StaticImage src="../../../images/team/employees/maja_matijevic.png" alt="Maja Matijević" />
					<h5 className={classes.employeeName}>Maja Matijević</h5>
					<p className={classes.employeePosition}>Developer</p>
				</Grid>

				<Grid item xs={12} sm={6} md={4} lg={3}>
					<StaticImage src="../../../images/team/employees/mario_pasturovic.png" alt="Mario Pastuović" />
					<h5 className={classes.employeeName}>Mario Pastuović</h5>
					<p className={classes.employeePosition}>Developer</p>
				</Grid>

				<Grid item xs={12} sm={6} md={4} lg={3}>
					<StaticImage src="../../../images/team/employees/placeholder.png" alt="" aria-hidden="true" />
					<h5 className={classes.employeeName}>Matej Jurišić</h5>
					<p className={classes.employeePosition}>System Administrator</p>
				</Grid>

				<Grid item xs={12} sm={6} md={4} lg={3}>
					<StaticImage src="../../../images/team/employees/placeholder.png" alt="" aria-hidden="true" />
					<h5 className={classes.employeeName}>Marko Solić</h5>
					<p className={classes.employeePosition}>QA Engineer</p>
				</Grid>

				<Grid item xs={12} sm={6} md={4} lg={3}>
					<StaticImage src="../../../images/team/employees/mia_stajic.jpg" alt="Mia Stajić" />
					<h5 className={classes.employeeName}>Mia Stajić</h5>
					<p className={classes.employeePosition}>Customer Support</p>
				</Grid>

				<Grid item xs={12} sm={6} md={4} lg={3}>
					<StaticImage src="../../../images/team/employees/ivona_miletic.jpg" alt="Ivona Miletić" />
					<h5 className={classes.employeeName}>Ivona Miletić</h5>
					<p className={classes.employeePosition}>CEO</p>
				</Grid>
			</Grid>
		</section>
	);
};

export default Employees;

const useClasses = makeStyles({
	section: {
		backgroundColor: colors.benefitsBackground,
		// paddingBottom: '8.375em',
		paddingTop: '6.75em',
		position: 'relative',
		'@media (max-width: 599px)': {
			paddingBottom: '5em',
			paddingTop: '0.5em',
		},
	},
	gridRoot: {
		maxWidth: defaultSectionWidth,
		margin: '0 auto',
		paddingLeft: '1em',
		paddingRight: '1em',
		'@media (max-width: 1231px)': {
			maxWidth: '100%',
		},
	},
	header: {
		fontFamily: RigrafExpanded.bold,
		fontSize: pageTitle.fontSize,
		lineHeight: pageTitle.lineHeight,
		marginTop: 0,
		marginBottom: 'calc(1.0714285714285714em - 16px)',
		whiteSpace: 'pre-wrap',
		color: colors.white,
		'@media (max-width: 599px)': {
			fontFamily: RigrafExpanded.semiBold,
			fontSize: largeText.fontSize,
			lineHeight: largeText.lineHeight,
			marginTop: '2em',
			marginBottom: '1em',
		},
	},
	employeeName: {
		marginTop: '2em',
		marginBottom: '0.2em',
		color: colors.white,
	},
	employeePosition: {
		fontFamily: MetroSans.book,
		fontSize: smallText.fontSize,
		lineHeight: smallText.lineHeight,
		color: colors.lightGray,
		marginTop: '0.25em',
		marginBottom: 'calc(4em - 16px)',
		'@media (max-width: 599px)': {
			marginBottom: '0.5em',
		},
	},
	joinUsContainer: {
		backgroundColor: '#1F272E',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	joinUsText: {
		color: colors.white,
		fontFamily: RigrafExpanded.semiBold,
		fontSize: smallSubtitle.fontSize,
		lineHeight: smallSubtitle.lineHeight,
	},
});
